declare global {
  interface Window {
    Intercom: (action: string) => void;
  }
}

type ButtonsActionType = {
  intercom: () => void;
  cb: (cb: () => void) => void;
};

const openIntercom = () => {
  window?.Intercom('show');
};

export const runCb = (cb: () => void) => {
  cb();
};

export const BUTTON_ACTIONS: ButtonsActionType = {
  intercom: openIntercom,
  cb: runCb,
};
