import { iconTheme, sectionStyle } from 'holded/lib/styles';
import { Card } from 'holded/modules/cms/domain/components/header';
import Button from 'holded/modules/cms/ui/shared/components/Button';
import HeroIcon from 'holded/modules/cms/ui/shared/components/HeroIcon';
import NextImage from 'holded/modules/cms/ui/shared/components/Image';

const HEADER_ICON_THEME = iconTheme.basic;

const CardIcon = ({ id, image, icon, title, description, button }: Card) => {
  return (
    <div key={id} className={`flex flex-col ${sectionStyle.background.default} rounded-2xl shadow-xl`}>
      <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
        {image?.data ? (
          <div className="absolute top-0 h-16 w-16 inline-block transform -translate-y-1/2">
            <NextImage media={image.data} classImg={'object-cover'} />
          </div>
        ) : (
          <div
            className={`${HEADER_ICON_THEME} absolute top-0 p-5 inline-block rounded-xl shadow-lg transform -translate-y-1/2`}
          >
            <HeroIcon icon={icon} outline={true} />
          </div>
        )}

        <h3 className={`${sectionStyle.textColor.default} text-xl font-medium`}>{title}</h3>
        <p className={`mt-4 text-base ${sectionStyle.textColor.secondary}`}>{description}</p>
      </div>
      <div className={`p-6 ${sectionStyle.background.secondary} rounded-bl-2xl rounded-br-2xl md:px-8`}>
        <Button data={button} iconOptions={{ position: 'right', outline: false, size: 4 }}>
          {button?.text}
        </Button>
      </div>
    </div>
  );
};

export default CardIcon;
