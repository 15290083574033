import { FC, PropsWithChildren } from 'react';

type Props = {
  href?: string;
};

export const LinkSmoothScroll: FC<PropsWithChildren<Props>> = ({ href, children }) => {
  const scrollToElement = () => {
    href && document.getElementById(href)?.scrollIntoView({ behavior: 'smooth' });
  };

  return <span onClick={scrollToElement}>{children}</span>;
};
